import initAnalytics from './_includes/components/analytics/analytics'
import initInstallPrompt from './_includes/components/install-prompt/install-prompt'
import initMirrorStatus from './_includes/components/mirror-status/mirror-status.js'
import initNativeShare from './_includes/components/native-share/native-share.js'
import initSlider from './_includes/components/image-slider/image-slider'
import initBookmark from './_includes/components/bookmark/bookmark'
import initBookmarkCount from './_includes/components/bookmark-count/bookmark-count'
import initEmbedHandler from './_includes/components/embed-handler/embed-handler'
import initStaleContentNotification from './_includes/components/stale-content-notification/stale-content-notification'
import initBookmarkList from './pages/bookmarks/index'
import initNewsFeed from './_includes/components/news-feed/news-feed'
import initPromotedSlots from './_includes/components/promoted-slot/promoted-slot'
import initSectionFilter from './_includes/components/section-filter/section-filter'
import initForm from './_includes/components/form/form'
import initMailtoForm from './_includes/components/mailto-form/mailto-form'
import initThemeMenu from './_includes/components/theme-menu/theme-menu'
import initNewsletterSubscribe from './_includes/components/newsletter-subscribe/newsletter-subscibe'
import initFontSizeMenu from './_includes/components/font-size-menu/font-size-menu'
import setViewportHeight from './_includes/components/viewport-helper/viewport-helper'
import initToggle from './_includes/components/toggle/toggle'
import initReportForReview from './_includes/components/report-for-review/report-for-review'
import initInlineFootnote from './_includes/components/inline-footnote/inline-footnote'
import initPushSubscribe from './_includes/components/push-subscribe/push-subscribe'
import initSearchResults from './_includes/components/search-results/search-results'
import initAudiencePoll from './_includes/components/audience-poll/audience-poll'
import initOpinionPanelForm from './_includes/components/opinion-panel-form/opinion-panel-form'
import initCensorshipCircumventionChecklist from './pages/censorship-circumvention-checklist/'
import { initNotificationCenter } from './_includes/components/notification-center/notification-center'
import { initModal } from './_includes/components/modal/modal'
import initPrintBookmarks from './_includes/components/print-bookmarks/print-bookmarks'
import initShareInstructions from './_includes/components/share-instructions/share-instructions.js'
import initNewsletterSubscribeNotification from './_includes/components/newsletter-subscribe-notification/newsletter-subscribe-notification'
import initIranAfterDark from './_includes/components/iran-after-dark/iran-after-dark'
import initSkipLink from './_includes/components/skip-link/skip-link'
import initPostParagraph from './_includes/components/post-paragraph/post-paragraph'
import initHideForIran from './_includes/components/hide-for-iran/hide-for-iran'

initAnalytics()
initInstallPrompt()
initMirrorStatus()
initNativeShare()
initSlider()
initBookmark()
initBookmarkCount()
initEmbedHandler()
initBookmarkList()
initStaleContentNotification()
initNewsFeed()
initPromotedSlots()
initForm()
initSectionFilter()
initMailtoForm()
initThemeMenu()
initNewsletterSubscribe()
initFontSizeMenu()
setViewportHeight()
initToggle()
initReportForReview()
initInlineFootnote()
initPushSubscribe()
initSearchResults()
initAudiencePoll()
initOpinionPanelForm()
initCensorshipCircumventionChecklist()
initNotificationCenter()
initModal()
initPrintBookmarks()
initNewsletterSubscribeNotification()
initShareInstructions()
initIranAfterDark()
initSkipLink()
initPostParagraph()
initHideForIran()
